import React from "react"
import { Link } from "gatsby"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Cta from "../components/section-cta"
import GeneralIcon from "../assets/images/icon-general.svg"
import SellerIcon from "../assets/images/icon-seller.svg"
import BuyerIcon from "../assets/images/icon-buyer.svg"
import DeliveringIcon from "../assets/images/icon-delivering.svg"
import ShieldLockIcon from "../assets/images/shield-lock-icon.svg"

import iconGeneral from "../assets/images/faq/icon-general.svg"
import iconAccount from "../assets/images/faq/icon-account.svg"
import iconSafety from "../assets/images/faq/icon-safety.svg"
import iconSeller from "../assets/images/faq/icon-seller.svg"
import iconBuyer from "../assets/images/faq/icon-buyer.svg"
import iconShipping from "../assets/images/faq/icon-shipping.svg"
import iconPayments from "../assets/images/faq/icon-payments.svg"
import iconSecurity from "../assets/images/faq/icon-security.svg"

import faqs from "../data/faq.json"

SwiperCore.use([Navigation])

const HelpCenter = () => {
  const faqData = [...faqs]

  const topQuestion = [
    {
      category: "Account",
      question: `What are the accepted payment options?`,
      answer:
        "Customers can pay using the payment methods set by the seller/service provider on each post. Please note that for payments made using credit/debit, e-wallets, and PayPal, additional fees will be deducted per sale.",
    },
    {
      category: "General",
      question: `How to create a Servbees account`,
      answer:
        "Customers can pay using the payment methods set by the seller/service provider on each post. Please note that for payments made using credit/debit, e-wallets, and PayPal, additional fees will be deducted per sale.",
    },
    {
      category: "Seller",
      question: `How to sell in Servbees`,
      answer:
        "Customers can pay using the payment methods set by the seller/service provider on each post. Please note that for payments made using credit/debit, e-wallets, and PayPal, additional fees will be deducted per sale.",
    },
    {
      category: "Buyer",
      question: `How to buy items`,
      answer:
        "Customers can pay using the payment methods set by the seller/service provider on each post. Please note that for payments made using credit/debit, e-wallets, and PayPal, additional fees will be deducted per sale.",
    },
  ]

  return (
    <Layout className="help-center">
      <SEO
        pageTitle="Frequently ask question"
        title="Help Center | Servbees"
        description="Ready to use Servbees? Be a responsible Buzzybee by reading the terms and conditions before using our app."
      />
      <Banner title="Help Center" />
      <div className="section-articles">
        <div className="container">
          <div className="content-wrapper">
            <div className="content-holder">
              <h3 className="title">Browse Articles</h3>
            </div>
            <div className="content-holder">
              {!!faqData &&
                faqData.map((faq, i) => (
                  <div key={i} className="card-holder">
                    <img
                      src={
                        faq.name.includes("General")
                          ? iconGeneral
                          : faq.name.includes("Account")
                          ? iconAccount
                          : faq.name.includes("Safety")
                          ? iconSafety
                          : faq.name.includes("Seller")
                          ? iconSeller
                          : faq.name.includes("Buyer")
                          ? iconBuyer
                          : faq.name.includes("Shipping")
                          ? iconShipping
                          : faq.name.includes("Payments")
                          ? iconPayments
                          : iconSecurity
                      }
                      className="icon"
                      alt=""
                    />
                    <h3 className="category-name">{faq.name}</h3>
                    <ul className="article-list">
                      {faq.topics.map((topic, i) => (
                        <li key={i} className="article-list-item">
                          <Link
                            to={`https://servbees.com/faq-inner/${topic.slug}`}
                            className="article-title"
                          >
                            {topic.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  )
}

export default HelpCenter
